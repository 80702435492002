<template>
  <page-header-wrapper>
    <a-card :body-style="{padding: '24px 32px'}" :bordered="false">
        <a-form-item
          label="性别"
          :labelCol="{lg: {span: 7}, sm: {span: 7}}"
          :wrapperCol="{lg: {span: 10}, sm: {span: 17} }"
          :required="false"
        >
          <a-radio-group v-decorator="['target', { initialValue: 1 }]"  v-model="values" @change="onChange2">
            <a-radio :value="1">男</a-radio>
            <a-radio :value="2">女</a-radio>
          </a-radio-group>
        </a-form-item>
      <a-form-item
          label="年龄"
          :labelCol="{lg: {span: 7}, sm: {span: 7}}"
          :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
          <a-input
            v-decorator="[
              'age',
              {rules: [{ required: true, message: '请输入年龄' }]}
            ]"
            name="age"
            placeholder=" " 
            v-model="age"/>
        </a-form-item>
        <a-form-item
          label="工作单位"
          :labelCol="{lg: {span: 7}, sm: {span: 7}}"
          :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
          <a-input
            v-decorator="[
              'dw',
              {rules: [{ required: true, message: '请输入工作单位' }]}
            ]"
            name="dw"
            placeholder=" " 
             v-model="dw"/>
        </a-form-item>
        <a-form-item
          label="职称"
          :labelCol="{lg: {span: 7}, sm: {span: 7}}"
          :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
          <a-select default-value="专家职称" @change="handelzc">
        <a-select-option :value="zhi" v-for="zhi in this.zc" :key="zhi" >
          {{zhi}}
        </a-select-option>
      </a-select>
            
        </a-form-item>
        
        <a-form-item
          label="从事领域"
          :labelCol="{lg: {span: 7}, sm: {span: 7}}"
          :wrapperCol="{lg: {span: 10}, sm: {span: 17} }">
          <a-textarea
            rows="4"
            placeholder=" "
            v-decorator="[
              'description',
              {rules: [{ required: true, message: '请专家从事领域' }]}
            ]"
            v-model="csly" />
        </a-form-item>
        <a-form-item>
          <a-button htmlType="submit" type="primary" style="display:block;margin:0 auto" @click="handlesearch"> 搜索匹配 </a-button>
        </a-form-item>
    </a-card>
    <a-card :bordered="false">
      <a-table :columns="columns" :data-source="data">
        <a slot="name" slot-scope="text">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
        <span slot="tags" slot-scope="tags">
          <a-tag
            v-for="tag in tags"
            :key="tag"
            :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
          >
            {{ tag.toUpperCase() }}
          </a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a> {{ record.cxd }}</a>
        </span>
      </a-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import {getzc,pipei} from '../../../../../api/result'
const columns = [
  {
    title: '专家姓名',
    dataIndex: 'zjname',
    key: 'zjname'
  },
  {
    title: '工作单位',
    dataIndex: 'dw',
    key: 'dw'
  },
  {
    title: '性别',
    dataIndex: 'xb',
    key: 'xb'
  },
  {
    title: '年龄',
    dataIndex: 'age',
    key: 'age'
  },
  {
    title: '职称',
    dataIndex: 'zc',
    key: 'zc'
  },
  {
    title: '从事领域',
    key: 'csly',
    dataIndex: 'csly'
  }
]

const data = [
 
]

export default {
  data () {
    return {
      data,
      columns,
      values:'',
      zc:'',
      age:'',
      dw:'',
      xb:'',
      csly:'',
      zc2:''
      
    }
  },
  mounted(){
getzc()
.then(res=>{
  console.log(res)
  this.zc=res.data
})
  },
  methods:{
    handlesearch(){
      console.log(this.xb,this.age,this.dw,this.zc2,this.csly)
      pipei({
        xb:this.xb,
        dw:this.dw,
        csly:this.csly,
        age:this.age,
        zc:this.zc2
      })
      .then(res=>{
        console.log(res)
        this.data=res.data
      })
    },
    onChange2(e){
      console.log(e.target.value)
      if(e.target.value==1){
        this.xb="男"
      }else{
        this.xb="女"
      }
    },
    handelzc(e){
    console.log(e)
    this.zc2=e
    }
  }
}
</script>

<style scoped>
.components-input-demo-size .ant-input {
  width: 200px;
  margin: 0 8px 8px 0;
}
</style>
